import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';

const useGroupRequest = (userId) => {
    const [groupRequest, setGroupRequest] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchGroupRequest = async () => {
            if (userId) {
                try {
                    setLoading(true);
                    const { data, error } = await supabase
                        .from('group_requests')
                        .select('*')
                        .eq('user_id', userId);
    
                    if (error) {
                        setError(error.message);
                    } else if (data.length === 0) {
                        setGroupRequest(null);
                    } else {
                        setGroupRequest(data[0]);
                    }
                } catch (err) {
                    setError(err.message);
                } finally {
                    setLoading(false);
                }
            }
        };
    
        fetchGroupRequest();
    }, [userId]);
    
    

    const deleteGroupRequest = async () => {
        try {
            setLoading(true);
            const { error } = await supabase
                .from('group_requests')
                .delete()
                .eq('user_id', userId);

            if (error) {
                setError(error.message);
            } else {
                setGroupRequest(null);
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { groupRequest, deleteGroupRequest, loading, error };
};

export default useGroupRequest;

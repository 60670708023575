import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useUserId } from '../../hooks/authversionhooks/useUserId';
import useFetchUserStatus from '../../hooks/authversionhooks/useFetchUserStatus';
import './UserGroupDashboard.css';
import LatestTargetsAttacked from './LatestTargetsAttacked';

const Defender = ({ defender, defender_ingame_id, battlestatscore, userBattleStats, shouldFetchStatuses }) => {
  const userStatus = useFetchUserStatus(defender_ingame_id, shouldFetchStatuses);


  const generateProfileLink = (defender_ingame_id) => `https://www.torn.com/profiles.php?XID=${defender_ingame_id.toString()}`;


  const calculateFairFight = (defenderScore, attackerScore) => 1 + ((8 / 3) * (defenderScore / attackerScore));

  return (
    <tr>
      <td><a href={generateProfileLink(defender_ingame_id)} target="_blank" rel="noopener noreferrer">{defender}</a></td>
      <td>{userStatus}</td>
      <td>{battlestatscore.toFixed(2)}</td>
      <td>{userBattleStats && calculateFairFight(battlestatscore, userBattleStats).toFixed(2)}</td>
    </tr>
  );
}

const UserGroupDashboard = () => {
  const userId = useUserId();
  const [defenderStats, setDefenderStats] = useState([]);
  const [userBattleStats, setUserBattleStats] = useState(null); 
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [shouldFetchStatuses, setShouldFetchStatuses] = useState(true);
  
  const changePage = (newPage) => {
    if(newPage !== 1){
      setShouldFetchStatuses(false);
    }
    setCurrentPage(newPage);
  };
  

  const fetchDefenderStats = async (userId, pageNumber, pageSize) => {
    try {
      const { data, error, status } = await supabase.rpc('fetch_defender_stats', { p_user_id: userId, page_number: pageNumber, page_size: pageSize });
    
      if (error || status !== 200) {
        console.error('Error fetching defender stats:', error);
        throw error;
      }
    
      return data;
    } catch (error) {
      console.error('Error fetching defender stats:', error.message);
      setError('An error occurred while fetching defender stats.'); 
      return []; 
    }
  };
  

  const fetchUserBattleStats = async (userId) => {
    try {
      let { data, error } = await supabase
        .from('user_battle_stats')
        .select('user_battle_stats_score')
        .eq('user_id', userId)
        .single(); 

      if (error) {
        throw error;
      }

      return data.user_battle_stats_score;
    } catch (error) {
      console.error('Error fetching user battle stats:', error);
    }
  };

  useEffect(() => {
    if (userId) {
      fetchDefenderStats(userId, currentPage, 20)
        .then(data => setDefenderStats(data));
      fetchUserBattleStats(userId) 
        .then(data => data && setUserBattleStats(parseFloat(data.toFixed(2)))); 
    }
  }, [userId, currentPage]);

  return (
    <div>
      <LatestTargetsAttacked />
      
      {userBattleStats && <h3>Your Battle Stat Score: {userBattleStats.toFixed(2)}</h3>}
      {<button onClick={() => setShouldFetchStatuses(true)}>Fetch Status for All</button>}
      {error && <p>{error}</p>}
      {defenderStats.length > 0 ? (
        <div>
          <table className="stats-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Status</th>
                <th>Battle Stat Score</th>
                <th>Fairfight Multiplier</th>
              </tr>
            </thead>
            <tbody>
              {defenderStats.map((defenderStat, index) => (
                <Defender 
                key={defenderStat.id} 
                userBattleStats={userBattleStats} 
                shouldFetchStatuses={shouldFetchStatuses}
                {...defenderStat} 
             />
             
              ))}
            </tbody>
          </table>
          <button disabled={currentPage === 1} onClick={() => changePage(currentPage - 1)}>Previous</button>
          <button onClick={() => changePage(currentPage + 1)}>Next</button>

        </div>
      ) : (
        <p>No defender stats available.</p>
      )}
    </div>
  );
};

export default UserGroupDashboard;

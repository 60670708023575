// components/GroupRequests.js
import { supabase } from '../../supabaseClient';


import React, { useEffect, useState } from 'react';
import useManageRequests from '../../hooks/authversionhooks/useManageRequests';

const GroupRequests = ({ groupName }) => {
    const [requests, setRequests] = useState([]);
    const { manageRequest, loading, error } = useManageRequests();

    useEffect(() => {
        const fetchRequests = async () => {
            const { data, error } = await supabase
                .from('group_requests')
                .select('*')
                .eq('group_name', groupName)
                .eq('status', 'Pending');
            
            if (error) {
                console.log('Error fetching requests: ', error);
            } else {
                setRequests(data);
            }
        };
        fetchRequests();
    }, [groupName]);
    

    const handleApprove = async (id, userId, userName) => {  // <-- Add userName parameter
        await manageRequest(id, 'Approved', groupName, userId, userName);  // <-- Pass userName
    };
    
    
    const handleReject = async (id) => {
        await manageRequest(id, 'Rejected');
    };
    

    return (
        <div>
            {requests.map(request => (
                <div key={request.id}>
                    <p>Request from user {request.user_player_name}</p> {/* Changed from {request.user_id} */}
                    <button onClick={() => handleApprove(request.id, request.user_id, request.user_player_name)} disabled={loading}>Approve</button> 

                    <button onClick={() => handleReject(request.id)} disabled={loading}>Reject</button>
                </div>
            ))}
            {error && <p>Error: {error}</p>}
        </div>
    );
};

export default GroupRequests;

import { useUser } from '../../components/authversion/UserContext';
import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';

export const useUserGroupId = () => {
  const { user } = useUser();
  const [groupId, setGroupId] = useState(null);

  useEffect(() => {
    if (user && user.user) {
      const fetchGroupId = async () => {
        try {
          let { data, error } = await supabase
            .from('group_member')
            .select('group_id')
            .eq('user_id', user.user.id)
            .single();
  
          if (error) {
            console.error('Error fetching Group ID: ', error);
            return;
          }
          
          if (data) {
            setGroupId(data.group_id);
          }
        } catch (err) {
          console.error('Error: ', err);
        }
      };

      fetchGroupId();
    }
  }, [user]);

  return groupId;
};

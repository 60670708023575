import React from 'react';
import { useMatch, NavLink, useLocation } from 'react-router-dom'; // Import useLocation
import './NavBar.css';

const CustomNavLink = ({ to, children }) => {
  const match = useMatch(to); // This will be true if the current path is the same as `to`
  
  return (
    <li>
      <NavLink to={to} className={`link ${match ? 'active-link' : ''}`}>{children}</NavLink>
    </li>
  )
}

const NavBar = () => {
  // You can remove this line if you don't need userId anymore
  // const userId = useUserId();
  
  const location = useLocation(); // Get the current location

  return (
    <nav className="navbar">
      <ul>
        <CustomNavLink to="/">Targets Library</CustomNavLink>
        <CustomNavLink to="/BSS">BSS Calculator</CustomNavLink>
        <CustomNavLink to="/SignInPage">Private Groups Login</CustomNavLink>
        
        
        {/* Check if the current path is not the homepage before showing "Ideal Targets From Group" */}
        {location.pathname !== '/' && (
          <>
            <CustomNavLink to="/user-group-dashboard">Ideal Targets From Group</CustomNavLink>
          </>
        )}
      </ul>
    </nav>
  );
};

export default NavBar;

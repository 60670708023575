import { useState, useEffect } from 'react';
import { useUserApiKey } from './useUserApiKey';

const useFetchUserStatus = (defender_ingame_id, shouldFetchStatuses) => {
  const apiKey = useUserApiKey();
  const [userStatus, setUserStatus] = useState(null);

  useEffect(() => {
    const fetchUserStatus = async () => {
      try {
        if (!apiKey || !defender_ingame_id || !shouldFetchStatuses) return;

        const response = await fetch(`https://api.torn.com/user/${defender_ingame_id}?selections=profile&comment=TornAPI&key=${apiKey}`);
        const data = await response.json();
        
        // Check for error in the API response and throw it as a string
        if (data.error) {
          throw new Error(JSON.stringify(data.error));
        }
    
        setUserStatus(data.status.state);
      } catch (err) {
        console.error('Error fetching user status: ', err);
      }
    };

    fetchUserStatus();
  }, [apiKey, defender_ingame_id, shouldFetchStatuses]);

  return userStatus;
};


export default useFetchUserStatus;

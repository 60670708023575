import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import StockApp from './components/unrelated/StockApp';
import BattleStatsComponentManual from './components/bsscalculator/BattleStatsComponentManual';
import LatestTargetsAttacked from './components/authversion/LatestTargetsAttacked';
import SignInPage from './components/authversion/SignInPage';
import UserGroupDashboard from './components/authversion/UserGroupDashboard';
import { UserProvider } from './components/authversion/UserContext';
import Testing from './components/authversion/Testing';
import GroupPage from './components/authversion/GroupPage';
import RequestToJoinGroup from './components/authversion/RequestToJoinGroup';
import NavBar from './components/navbar/NavBar';
import TargetsLibrary from './components/targetslibrary/TargetsLibrary';
import Footer from './components/footer/Footer';
import './App.css';

//check
function RoutesWithUserProvider() {
  return (
    <UserProvider>
      <Routes>
        <Route path="/stock-app" element={<StockApp />} />
        <Route path="/BSS" element={<BattleStatsComponentManual />} />
        <Route path="/automatMore" element={<LatestTargetsAttacked />} />
        <Route path="/SignInPage" element={<SignInPage />} />
        <Route path="/Testing" element={<Testing />} />
        <Route path="/Group" element={<GroupPage />} />
        <Route path="/user-group-dashboard" element={<UserGroupDashboard />} />
        <Route path="/request-to-join-group" element={<RequestToJoinGroup />} />
      </Routes>
    </UserProvider>
  );
}

function App() {
  
  return (
    <Router>
      <div className="app-content">
        <NavBar />
        <Routes>
          <Route path="/" element={<TargetsLibrary />} />
          <Route path="/*" element={<RoutesWithUserProvider />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;

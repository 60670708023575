import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';

const useUserGroup = (userId) => {
  const [userGroup, setUserGroup] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserGroup = async () => {
      if (userId) {
        try {
          setLoading(true);
          const { data, error } = await supabase
            .from('group_member')
            .select('group_id')
            .eq('user_id', userId);

          if (error) {
            setError(error.message);
          } else if (data.length === 0) {
            setUserGroup(null);
          } else {
            setUserGroup(data[0].group_id);
          }
        } catch (err) {
          setError(err.message);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchUserGroup();
  }, [userId]);

  return { userGroup, loading, error };
};

export default useUserGroup;

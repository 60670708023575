// hooks/useManageRequests.js
import { useState } from 'react';
import { supabase } from '../../supabaseClient';

const useManageRequests = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const manageRequest = async (requestId, decision, groupName, userId, userName) => {
    setLoading(true);
    try {
      const { error: deleteError } = await supabase
        .from('group_requests')
        .delete()
        .eq('id', requestId);
      
      if (deleteError) {
        throw deleteError;
      }

      // If the request is approved, add the user to the group.
      if (decision === 'Approved') {
        const { data: groupData, error: groupError } = await supabase
          .from('user_group')
          .select('id')
          .eq('group_name', groupName)
          .single();

        if (groupError) {
          throw groupError;
        }

        const { error: insertError } = await supabase
          .from('group_member')
          .insert([
            { group_id: groupData.id, user_id: userId, user_player_name: userName },
          ]);

        if (insertError) {
          throw insertError;
        }
      }

      setLoading(false);
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return {
    manageRequest,
    loading,
    error,
  };
};

export default useManageRequests;

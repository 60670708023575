import { useState, useEffect } from 'react';
import { useUserApiKey } from './useUserApiKey';

const useTornUserName = () => {
  const apiKey = useUserApiKey();
  const [userName, setUserName] = useState(null);

  useEffect(() => {
    if (apiKey) {
      const fetchUserName = async () => {
        try {
          const response = await fetch(`https://api.torn.com/user/?selections=profile&comment=TornAPI&key=${apiKey}`);
          const data = await response.json();
          setUserName(data.name);
        } catch (err) {
          console.error('Error: ', err);
        }
      };
      
      fetchUserName();
    }
  }, [apiKey]);

  return userName;
};

export default useTornUserName;

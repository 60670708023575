const Footer = () => {
    return (
      <footer style={{ backgroundColor: '#f1f1f1', padding: '1rem', textAlign: 'center' }}>
        <p>Favicon picture designed by Freepik</p>
        <p>Website by <a href="https://www.torn.com/profiles.php?XID=2080313">SHIR123321</a></p>
      </footer>
    );
  };
  
  export default Footer;
  
// hooks/useGroupLeadership.js

import { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useUserId } from './useUserId';

const useGroupLeadership = () => {
  const userId = useUserId();
  const [groupName, setGroupName] = useState(null);
  const [groupMembers, setGroupMembers] = useState([]);

  useEffect(() => {
    fetchGroupLeadership();
  }, [userId]);

  const fetchGroupLeadership = async () => {
    if (userId) {
      const { data, error } = await supabase
        .from('user_group')
        .select('group_name, id')
        .eq('leader_id', userId);

      if (error) {
        console.log('Error fetching group leadership: ', error);
      } else if (data.length > 0) {
        setGroupName(data[0].group_name);
        fetchGroupMembers(data[0].id);  // Fetch group members using the group id
      } else {
        setGroupName(null);
      }
    }
  };

  const fetchGroupMembers = async (groupId) => {
    const { data, error } = await supabase
      .from('group_member')
      .select('user_player_name')
      .eq('group_id', groupId);

    if (error) {
      console.log('Error fetching group members: ', error);
    } else if (data) {
      setGroupMembers(data.map(item => item.user_player_name));
    }
  };

  return { groupName, groupMembers };
};

export default useGroupLeadership;

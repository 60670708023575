import React, { useState, useEffect } from 'react';
import useFetchFairFightData from '../../hooks/authversionhooks/useFetchFairFightData';
import { supabase } from '../../supabaseClient';
import { useUserId } from '../../hooks/authversionhooks/useUserId';
import { useUserGroupId } from '../../hooks/authversionhooks/useUserGroupId';

const LatestTargetsAttacked = () => {
  const [isKeySubmitted, setIsKeySubmitted] = useState(false);
  const userId = useUserId();
  const groupId = useUserGroupId();

  const submitApiKey = event => {
    event.preventDefault();
    setIsKeySubmitted(true);
  };

  const {fairFightsData, attackerStats} = useFetchFairFightData(isKeySubmitted);


  const storeDataInDatabase = async (data, attackerBattleStatScore) => {
    let { data: existingDefendersData, error } = await supabase
      .from('defender_stats')
      .select('*');

    if (error) {
      console.error('Error fetching defender data:', error);
      return;
    }

    // Create an object for quick access to existing defender data
    const existingDefenders = existingDefendersData.reduce((obj, item) => {
      obj[item.defender] = item;
      return obj;
    }, {});

    // Split new data into data to update and data to insert
    const dataToUpdate = [];
    const dataToInsert = [];

    // Calculate user's battle stats score
    

    Object.keys(data).forEach(defender => {
      let { defender_ingame_id, battlestatScore } = data[defender];
    
      if (typeof battlestatScore === 'number') {
        if (existingDefenders[defender]) {
          dataToUpdate.push({
            ...existingDefenders[defender],
            defender_ingame_id: defender_ingame_id,
            battlestatscore: battlestatScore,
            group_id: groupId,
          });
        } else {
          dataToInsert.push({
            defender,
            defender_ingame_id: defender_ingame_id,
            battlestatscore: battlestatScore,
            attacker_id: userId,
            group_id: groupId,
          });
        }
    
        
      }
    });
    

    // Update user's battle stats score in the database
    try {
      await supabase.from('user_battle_stats').upsert({
        user_id: userId,
        user_battle_stats_score: attackerBattleStatScore,
        
      }, {
        returning: 'minimal', // This option will return only the basic response of operation 
      });
    } catch (error) {
      console.error('Error updating user battle stats:', error);
    }

    // Now update and insert data with a single request for each operation
    try {
      if (dataToUpdate.length > 0) {
        await supabase.from('defender_stats').upsert(dataToUpdate, { returning: 'minimal' });
      }

      if (dataToInsert.length > 0) {
        await supabase.from('defender_stats').insert(dataToInsert, { returning: 'minimal' });
      }
    } catch (error) {
      console.error('Error inserting/updating data:', error);
    }
  };

  useEffect(() => {
    if (isKeySubmitted && Object.keys(fairFightsData).length > 0) {
      const attackerBattleStatScore =
        Math.sqrt(attackerStats.defense) +
        Math.sqrt(attackerStats.speed) +
        Math.sqrt(attackerStats.dexterity) +
        Math.sqrt(attackerStats.strength);
      storeDataInDatabase(fairFightsData, attackerBattleStatScore);
    }
  }, [fairFightsData, isKeySubmitted, attackerStats]);
  

  return (
    <div>
      <button onClick={submitApiKey}>Update BTS & Contribute Targets</button>

      {/* {isKeySubmitted && Object.keys(fairFightsData).length > 0 && (
        <div>
          <h1>Fair Fights Data:</h1>
          {Object.entries(fairFightsData).map(([defender, data]) => (
            <p key={defender}>
              {defender}: fairFightMultiplier - {data.fairFightMultiplier}, Battlestat Score - {data.battlestatScore}
            </p>
          ))}
        </div>
      )} */}
    </div>
  );
};

export default LatestTargetsAttacked;

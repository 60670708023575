// GroupPage.js

import React, { useState } from 'react';
import useCreateGroup from '../../hooks/authversionhooks/useCreateGroup';
import { useUserId } from '../../hooks/authversionhooks/useUserId';
import useGroupLeadership from '../../hooks/authversionhooks/useGroupLeadership';
import GroupRequests from './GroupRequests';

const GroupPage = () => {
    const currentUserId = useUserId();
    const { groupName: leaderGroupName, groupMembers } = useGroupLeadership(); 
    const { createGroup, loading, error } = useCreateGroup();
    const [groupCreated, setGroupCreated] = useState(false);
    const [groupName, setGroupName] = useState('');

    const handleCreateGroup = async () => {
        const newGroup = await createGroup(currentUserId, groupName);
        if (newGroup) {
            setGroupCreated(true);
        }
    };

    return (
        <div>
            <h1>Group Page</h1>
            <input
                type="text"
                placeholder="Group Name"
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
            />
            {!groupCreated ? (
                <button onClick={handleCreateGroup} disabled={loading || !groupName}>
                    {loading ? 'Creating...' : 'Create Group'}
                </button>
            ) : (
                <p>Group successfully created!</p>
            )}
            {error && <p>Error: {error}</p>}
            {leaderGroupName && <GroupRequests groupName={leaderGroupName} />} 
            {groupMembers.length > 0 && (
                <div>
                    <h2>Group Members</h2>
                    <ul>
                        {groupMembers.map((member, index) => <li key={index}>{member}</li>)}
                    </ul>
                </div>
            )}
        </div>
    );
};

export default GroupPage;

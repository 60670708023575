import { useState } from 'react';
import { supabase } from '../../supabaseClient';

const useLeaveGroup = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const leaveGroup = async (groupId, userId) => {
        setLoading(true);
        try {
            const { error: leaveError } = await supabase
                .from('group_member')
                .delete()
                .eq('group_id', groupId)
                .eq('user_id', userId);

            if (leaveError) {
                throw leaveError;
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return {
        leaveGroup,
        loading,
        error,
    };
};

export default useLeaveGroup;

import React, { useState } from 'react';
import { useUserId } from '../../hooks/authversionhooks/useUserId';
import useRequestToJoinGroup from '../../hooks/authversionhooks/useRequestToJoinGroup';
import useTornUserName from '../../hooks/authversionhooks/useTornUserName';
import useGroupRequest from '../../hooks/authversionhooks/useGroupRequest';
import useUserGroup from '../../hooks/authversionhooks/useUserGroup';
import useLeaveGroup from '../../hooks/authversionhooks/useLeaveGroup';
import useKickMember from '../../hooks/authversionhooks/useKickMember';

const RequestToJoinGroup = () => {
    const currentUserId = useUserId();
    const currentPlayerName = useTornUserName();
    const { requestToJoinGroup, loading, error } = useRequestToJoinGroup();
    const [groupName, setGroupName] = useState('');
    const [requestSent, setRequestSent] = useState(false);
    const { groupRequest, deleteGroupRequest, loading: groupRequestLoading, error: groupRequestError } = useGroupRequest(currentUserId);
    const { userGroup, loading: userGroupLoading, error: userGroupError } = useUserGroup(currentUserId);
    const { leaveGroup, loading: leaveGroupLoading, error: leaveGroupError } = useLeaveGroup();
    const { kickMember, loading: kickMemberLoading, error: kickMemberError } = useKickMember();
    const [memberNameToKick, setMemberNameToKick] = useState(''); // New state for member name to be kicked

    const handleRequestToJoinGroup = async () => {
        const request = await requestToJoinGroup(groupName, currentUserId, currentPlayerName);
        if (request) {
            setRequestSent(true);
        }
    };

    const handleLeaveGroup = async () => {
        if (userGroup) {
            await leaveGroup(userGroup, currentUserId);
        }
    };

    const handleKickMember = async () => {  // Update this function
        if (userGroup && memberNameToKick) {
            await kickMember(userGroup, memberNameToKick);  // Pass memberNameToKick instead of memberId
        }
    };

    return (
        <div>
            <h1>Request to Join a Group</h1>
            <input
                type="text"
                value={groupName}
                onChange={e => setGroupName(e.target.value)}
                placeholder="Enter group name"
            />
            <button onClick={handleRequestToJoinGroup} disabled={loading}>
                {loading ? 'Sending Request...' : 'Send Request'}
            </button>
            {requestSent && <p>Request to join {groupName} sent!</p>}
            {error && <p>Error: {error}</p>}
            {groupRequest && (
                <button onClick={deleteGroupRequest} disabled={groupRequestLoading}>
                    {groupRequestLoading ? 'Deleting Request...' : 'Delete Request'}
                </button>
            )}
            {groupRequestError && <p>Error: {groupRequestError}</p>}
            {userGroup && !userGroupLoading && (
                <div>
                    <button onClick={handleLeaveGroup} disabled={leaveGroupLoading}>
                        {leaveGroupLoading ? 'Leaving group...' : 'Leave Current Group'}
                    </button>
                    <input
                        type="text"
                        value={memberNameToKick}
                        onChange={e => setMemberNameToKick(e.target.value)}
                        placeholder="Enter member name to kick"
                    />
                    <button onClick={handleKickMember} disabled={kickMemberLoading}>
                        {kickMemberLoading ? 'Kicking member...' : 'Kick Member'}
                    </button>
                </div>
            )}
            {leaveGroupError && <p>Error: {leaveGroupError}</p>}
            {kickMemberError && <p>Error: {kickMemberError}</p>}
        </div>
    );
};

export default RequestToJoinGroup;

import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import './TargetsLibrary.css';

const TargetsLibrary = () => {
  const [apiKey, setApiKey] = useState('');
  const [userName, setUserName] = useState(null);
  const [attackerStats, setAttackerStats] = useState({});
  const [attackerBattleStatScore, setAttackerBattleStatScore] = useState(null);
  const [fairFightsData, setFairFightsData] = useState({});
  const [targetsData, setTargetsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [error, setError] = useState(null);
  const [userStatuses, setUserStatuses] = useState({});
  const [defender_ingame_id, setDefenderIngameId] = useState(null);
  const [updateCount, setUpdateCount] = useState(0);
  const [competitionAttacks, setCompetitionAttacks] = useState(null);
  const [inputFactionID, setInputFactionID] = useState(localStorage.getItem('inputFactionID') || null);
  const [daysInFaction, setDaysInFaction] = useState(0);
  const [enterAttackerBattleStatScoreManualy, setEnterAttackerBattleStatScoreManualy] = useState(false);
  const [battleStatInput, setBattleStatInput] = useState("");
  const [accessLevel, setAccessLevel] = useState(null);
  const [manualAttackerBattleStatScore, setManualAttackerBattleStatScore] = useState(0);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 768);
  const [favoriteStatuses, setFavoriteStatuses] = useState({});
  const [showMain, setShowMain] = useState(() => {
    const saved = localStorage.getItem('showMain');
    return saved !== null ? JSON.parse(saved) : true; // default to true if not set
  });
  const [debuff, setDebuff] = useState(localStorage.getItem('debuff') === 'true' ? true : false);


  useEffect(() => {
    localStorage.setItem('showMain', JSON.stringify(showMain));
  }, [showMain]);

  const [lastUpdateOfFavorites, setLastUpdateOfFavorites] = useState(() => {
    return localStorage.getItem('lastUpdateOfFavoritesTimestamp');
  });
  const initialShowFavorites = JSON.parse(localStorage.getItem('showFavorites')) || false;
  const [showFavorites, setShowFavorites] = useState(initialShowFavorites);

  const toggleShowFavorites = () => {
    setShowFavorites(prevShowFavorites => !prevShowFavorites);
  };


  useEffect(() => {
    localStorage.setItem('showFavorites', JSON.stringify(showFavorites));
  }, [showFavorites]);
  const [favorites, setFavorites] = useState(() => {
    return JSON.parse(localStorage.getItem('favorites')) || [];
  });
  const addToFavorites = (target) => {
    let favorites = JSON.parse(localStorage.getItem('favorites')) || [];

    // Check if the target is already a favorite
    if (!favorites.some(favorite => favorite.id === target.id)) {
      favorites.push(target);
      localStorage.setItem('favorites', JSON.stringify(favorites));
    }
  };



  const timeSinceLastUpdateOfFavorites = new Date().getTime() - (lastUpdateOfFavorites || 0);
  const oneDayInMilliseconds = 24 * 60 * 60 * 1000;  // 24 hours in milliseconds

  const removeFromFavorites = (targetId) => {
    let favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    favorites = favorites.filter(favorite => favorite.id !== targetId);
    localStorage.setItem('favorites', JSON.stringify(favorites));
  };




  useEffect(() => {
    const handleResize = () => {

      setIsMobileView(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);



    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobileView]); // Depend on isMobileView to log its changes
  const [showRange, setShowRange] = useState(
    localStorage.getItem('showRange') === null
      ? false
      : JSON.parse(localStorage.getItem('showRange'))
  );

  useEffect(() => {
    localStorage.setItem('showRange', JSON.stringify(showRange));
  }, [showRange]);
  const [filterByFaction, setFilterByFaction] = useState(
    localStorage.getItem('filterByFaction') === null
      ? true
      : JSON.parse(localStorage.getItem('filterByFaction'))
  );

  const [shouldFetchStatuses, setShouldFetchStatuses] = useState(
    localStorage.getItem('shouldFetchStatuses') === null
      ? true
      : JSON.parse(localStorage.getItem('shouldFetchStatuses'))
  );


  useEffect(() => {
    if (inputFactionID !== null) {
      localStorage.setItem('inputFactionID', inputFactionID);
    } else {
      localStorage.removeItem('inputFactionID');
    }
  }, [inputFactionID]);


  // Persist filterByFaction in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('filterByFaction', JSON.stringify(filterByFaction));
  }, [filterByFaction]);

  // Persist shouldFetchStatuses in localStorage whenever it changes
  useEffect(() => {
    localStorage.setItem('shouldFetchStatuses', JSON.stringify(shouldFetchStatuses));
  }, [shouldFetchStatuses]);

  useEffect(() => {
    const fetchData = async () => {
      if (!apiKey) return;

      const response = await fetch(`https://api.torn.com/user/?comment=TornAPI&key=${apiKey}`);
      const data = await response.json();

      if (data.error) {
        throw new Error(JSON.stringify(data.error));
      }

      if (data.competition && data.competition.attacks !== undefined) {
        setCompetitionAttacks(data.competition.attacks);
      }
    };

    fetchData();
  }, [apiKey]);



  useEffect(() => {
    const fetchAccessLevel = async () => {
      try {
        const response = await fetch(`https://api.torn.com/key/?selections=info&comment=TornAPI&key=${apiKey}`);
        const data = await response.json();

        // Check for error in the API response and throw it as a string
        if (data.error) {
          throw new Error(JSON.stringify(data.error));
        }

        setAccessLevel(data.access_level);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (apiKey) fetchAccessLevel();
  }, [apiKey]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const rawScore = (enterAttackerBattleStatScoreManualy === true)
          ? Number(manualAttackerBattleStatScore.replace(/,/g, ''))
          : attackerBattleStatScore;

        const finalScore = debuff ? rawScore * 0.75 : rawScore;

        const params = {
          page_number: currentPage,
          page_size: 20,
          user_battlestatscore: finalScore,
        };

        if (filterByFaction) {
          params.given_faction_id = inputFactionID !== null ? parseInt(inputFactionID, 10) : 0;
        }

        const { data, error, status } = await supabase.rpc('fetch_targets_library', params);

        if (error || status !== 200) {
          console.error('Error fetching targets data:', error);
          throw error;
        }

        setTargetsData(data);
      } catch (error) {
        setError('An error occurred while fetching targets data.');
      }
    };

    if (attackerBattleStatScore !== null) {
      fetchData();
    }
  }, [currentPage, attackerBattleStatScore, filterByFaction, inputFactionID, manualAttackerBattleStatScore, enterAttackerBattleStatScoreManualy, debuff]); // Add debuff to dependency array





  useEffect(() => {
    // Load the API key, userName, and attackerBattleStatScore from localStorage when the component mounts
    const storedApiKey = localStorage.getItem('userApiKey');
    const storedUserName = localStorage.getItem('userName');
    const storedAttackerBattleStatScore = localStorage.getItem('attackerBattleStatScore');

    if (storedApiKey) {
      setApiKey(storedApiKey);
    }
    if (storedUserName) {
      setUserName(storedUserName);
    }
    if (storedAttackerBattleStatScore) {
      setAttackerBattleStatScore(Number(storedAttackerBattleStatScore));
    }
  }, []);

  useEffect(() => {
    if (apiKey) {
      localStorage.setItem('userApiKey', apiKey);
    }
  }, [apiKey]);

  useEffect(() => {
    if (userName) {
      localStorage.setItem('userName', userName);
    }
  }, [userName]);

  useEffect(() => {
    if (attackerBattleStatScore !== null) {
      localStorage.setItem('attackerBattleStatScore', attackerBattleStatScore.toString());
    }
  }, [attackerBattleStatScore]);


  useEffect(() => {
    const fetchData = async () => {
      if (!apiKey) return;

      const responseUserName = await fetch(`https://api.torn.com/user/?comment=TornAPI&key=${apiKey}`);
      const dataUserName = await responseUserName.json();

      // Check for error in the API response and throw it as a string
      if (dataUserName.error) {
        throw new Error(JSON.stringify(dataUserName.error));
      }

      setUserName(dataUserName.name);
      setDaysInFaction(dataUserName.faction.days_in_faction);

    };

    fetchData();
  }, [apiKey]);

  useEffect(() => {
    const fetchData = async () => {
      if (accessLevel < 3) return;
      try {
        const responseUserStats = await fetch(`https://api.torn.com/user/?selections=battlestats&comment=TornAPI&key=${apiKey}`);
        const dataUserStats = await responseUserStats.json();

        // Check for error in the API response and throw it as a string
        if (dataUserStats.error) {
          throw new Error(JSON.stringify(dataUserStats.error));
        }

        setAttackerStats(dataUserStats);
      } catch (error) {
        console.error('Error:', error);
      }
    };

    if (apiKey && accessLevel !== null) fetchData();
  }, [apiKey, accessLevel]);

  useEffect(() => {
    if (userName && attackerStats && Object.keys(attackerStats).length > 0) {
      setAttackerBattleStatScore(
        Math.sqrt(attackerStats.defense) +
        Math.sqrt(attackerStats.speed) +
        Math.sqrt(attackerStats.dexterity) +
        Math.sqrt(attackerStats.strength)
      )
    }
  }, [userName, attackerStats, apiKey]);

  useEffect(() => {
    const fetchData = async () => {
      if (!apiKey || accessLevel < 3 || !accessLevel) return;

      const response = await fetch(`https://api.torn.com/user/?selections=attacks&limit=50&comment=TornAPI&key=${apiKey}`);
      const data = await response.json();

      if (data.error) {
        throw new Error(JSON.stringify(data.error));
      }

      let newFairFightsData = {};

      for (let attackId in data.attacks) {
        const attackData = data.attacks[attackId];
        if (attackData.modifiers && attackData.modifiers.fair_fight && userName === attackData.attacker_name) {
          let estimatedDefenderBattleStatScore = "insufficient info";

          if (attackData.modifiers.fair_fight > 1.15 && attackData.modifiers.fair_fight < 3) {
            estimatedDefenderBattleStatScore = Math.round(
              attackerBattleStatScore * (attackData.modifiers.fair_fight - 1) * (3 / 8)
            );
          }

          newFairFightsData[attackData.defender_name] = {
            defender_ingame_id: attackData.defender_id,
            battlestatscore: estimatedDefenderBattleStatScore,
            faction_name: attackData.defender_factionname, // Update this line
            faction_id: attackData.defender_faction, // Update this line
          };
        }
      }

      setFairFightsData(newFairFightsData);
    };

    fetchData();
  }, [userName, attackerBattleStatScore, apiKey, accessLevel]);


  useEffect(() => {
    const storeDataInDatabase = async () => {
      // Conditionally exit if no API key or insufficient data is present

      if (accessLevel == null || accessLevel < 3 || !apiKey || !attackerBattleStatScore || attackerBattleStatScore < 5 || Object.keys(fairFightsData).length === 0 || daysInFaction <= 5) return;
      console.log(daysInFaction);


      let dataToInsert = Object.entries(fairFightsData)
        .map(([defender_name, data]) => ({
          defender_name,
          ...data
        }))
        .filter(entry => Number.isInteger(entry.battlestatscore) && entry.battlestatscore !== 0);


      try {
        const response = await supabase.from('targets_library').upsert(dataToInsert, { returning: 'minimal', onConflict: 'defender_ingame_id' });
        if (response.error) {
          console.error('Detailed error:', response.error.message);
        }
      } catch (error) {
        console.error('Error inserting/updating data:', error);
      }
    };

    storeDataInDatabase();
  }, [fairFightsData, apiKey, competitionAttacks, daysInFaction, attackerBattleStatScore, accessLevel]); // Added competitionAttacks to the dependency array

  async function updateFavoritesFromDatabase() {
    // 1. Fetch favorites from localStorage
    let favorites = JSON.parse(localStorage.getItem('favorites')) || [];

    // A list to hold the updated favorites
    const updatedFavorites = [];

    for (const favorite of favorites) {
      const { data, error } = await supabase
        .from('targets_library')
        .select('*')
        .eq('defender_ingame_id', favorite.defender_ingame_id)
        .single();

      // 2. If the favorite's data is found in Supabase, update its data.
      if (!error && data) {
        updatedFavorites.push(data);
      } else {
        // 3. If not found in Supabase, keep the original data.
        updatedFavorites.push(favorite);
      }
    }

    // 4. Update the localStorage with the updated favorites list
    localStorage.setItem('favorites', JSON.stringify(updatedFavorites));

    const currentTimestamp = new Date().getTime();
    setLastUpdateOfFavorites(currentTimestamp);
    localStorage.setItem('lastUpdateOfFavoritesTimestamp', currentTimestamp);

    // 5. (Optional) Set state or trigger a re-render in your component
    // You might want to trigger a state update or another action to reflect changes in the UI
  }

  const fetchStatusesForFavorites = async () => {
    const storedFavorites = localStorage.getItem('favorites');
    if (!storedFavorites) return;

    // Convert the storedFavorites from JSON string to an array
    const favoritesArray = JSON.parse(storedFavorites);

    for (let favorite of favoritesArray) {
      try {
        const response = await fetch(`https://api.torn.com/user/${favorite.defender_ingame_id}?selections=profile&comment=TornAPI&key=${apiKey}`);
        const data = await response.json();

        if (data.error) {
          throw new Error(JSON.stringify(data.error));
        }

        // In the fetchStatusesForFavorites function, modify the setUserStatuses to setFavoriteStatuses
        setFavoriteStatuses(prevStatuses => ({
          ...prevStatuses,
          [favorite.defender_ingame_id]: {
            state: data.status.state,
            lastAction: data.last_action.status,
            hospitalTimestamp: data.states.hospital_timestamp
          }
        }));

      } catch (err) {
        console.error(`Error fetching user status for ID ${favorite.defender_ingame_id}: `, err);
      }
    }
  };

  useEffect(() => {
    if (shouldFetchStatuses && showFavorites && apiKey) {
      fetchStatusesForFavorites();
    }
  }, [shouldFetchStatuses, showFavorites, apiKey]);
  useEffect(() => {
    localStorage.setItem('debuff', debuff.toString());
  }, [debuff]);




  useEffect(() => {

    const fetchAllUserStatuses = async () => {
      if (!apiKey || !shouldFetchStatuses || !showMain) return;

      // If inputFactionID exists and is not 0, use the faction-based API
      // If inputFactionID exists and is not 0, use the faction-based API
      if (filterByFaction && inputFactionID !== null && inputFactionID !== undefined && inputFactionID > 0) {

        try {
          const response = await fetch(`https://api.torn.com/faction/${inputFactionID}?comment=TornAPI&key=${apiKey}`);
          const data = await response.json();

          if (data.error) {
            throw new Error(JSON.stringify(data.error));
          }

          // Process each member's data from the faction response
          for (let memberId in data.members) {
            const member = data.members[memberId];
            setUserStatuses(prevStatuses => ({
              ...prevStatuses,
              [memberId]: {
                state: member.status.state,
                lastAction: member.last_action.status,
                // Set hospitalTimestamp based on the member's status
                hospitalTimestamp: member.status.state === 'Hospital' ? member.status.until : 0
              }
            }));
          }

        } catch (err) {
          console.error("Error fetching faction data: ", err);
        }
      }
      else {
        // Use the original target-based API
        for (let target of targetsData) {
          try {
            const response = await fetch(`https://api.torn.com/user/${target.defender_ingame_id}?selections=profile&comment=TornAPI&key=${apiKey}`);
            const data = await response.json();

            if (data.error) {
              throw new Error(JSON.stringify(data.error));
            }

            setUserStatuses(prevStatuses => ({
              ...prevStatuses,
              [target.defender_ingame_id]: {
                state: data.status.state,
                lastAction: data.last_action.status,
                hospitalTimestamp: data.states.hospital_timestamp
              }
            }));
          } catch (err) {
            console.error(`Error fetching user status for ID ${target.defender_ingame_id}: `, err);
          }
        }
      }
    };

    fetchAllUserStatuses();  // Call immediately

    let intervalId;

    // If using the faction-based approach, set up an interval to fetch data every 6 seconds
    if (filterByFaction && inputFactionID !== null && inputFactionID !== undefined && inputFactionID > 0) {

      intervalId = setInterval(() => {

        fetchAllUserStatuses();

      }, 6000);
    }

    // Cleanup
    return () => {

      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [apiKey, targetsData, shouldFetchStatuses, showMain, inputFactionID, filterByFaction]);


  useEffect(() => {
    if (shouldFetchStatuses) {
      const countdownInterval = setInterval(() => {
        // Force a re-render to update the countdown
        setUpdateCount(prevCount => prevCount + 1);
      }, 1000);

      return () => clearInterval(countdownInterval);
    }
  }, [shouldFetchStatuses]);

  const calculateTimeRemaining = (hospitalTimestamp) => {
    if (!hospitalTimestamp) return "N/A";

    const currentTime = Math.floor(Date.now() / 1000);  // Convert current time to seconds
    const timeRemaining = hospitalTimestamp - currentTime;

    if (timeRemaining < 0) return "0:00";

    const minutes = Math.floor(timeRemaining / 60);
    const seconds = timeRemaining % 60;

    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };



  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        console.log('Copied to clipboard:', text);
        // Optionally: Show a brief notification to the user
      })
      .catch(err => {
        console.error('Could not copy text:', err);
      });
  };

  const handleBattleStatSubmit = (e) => {
    e.preventDefault();
    const score = Number(battleStatInput);
    if (!isNaN(score) && score > 0) {
      setAttackerBattleStatScore(score);
    } else {
      console.error('Invalid BattleStatScore: Please enter a valid number greater than 0');
    }
    setBattleStatInput("");
  };




  function calculateStatsRange(battlestatscore) {
    if (!battlestatscore) return null;

    battlestatscore = Number(battlestatscore);
    if (isNaN(battlestatscore)) return null;

    // Minimum possible stats
    const equalStatValue = Math.pow(battlestatscore / 4, 2); // Each stat is assumed to be equal
    const lowestTotalStats = 4 * equalStatValue; // Summing all 4 equal stats

    // Maximum possible stats
    const highestTotalStats = Math.pow(battlestatscore, 2); // All points are in one stat

    return {
      lowest: lowestTotalStats, // Not calling toFixed here
      highest: highestTotalStats // Not calling toFixed here
    };
  }


  const isFavorite = (targetId) => {
    const favorites = JSON.parse(localStorage.getItem('favorites')) || [];
    return favorites.some(fav => fav.defender_ingame_id === targetId);
  };




  const handleSubmit = async event => {
    event.preventDefault();

    // Save the API key, userName, and attackerBattleStatScore to localStorage when the form is submitted
    localStorage.setItem('userApiKey', apiKey);
    if (userName) localStorage.setItem('userName', userName);
    if (attackerBattleStatScore) localStorage.setItem('attackerBattleStatScore', attackerBattleStatScore.toString());
  }

  const handleStatusClick = (id) => {
    setDefenderIngameId(id);
  }



  return (
    <div>
      <p>Seeking Public api keys for temporary use, Please contact me in-game at <a href="https://www.torn.com/profiles.php?XID=2080313">SHIR123321</a> thx for all who helped so far! </p>
      {attackerBattleStatScore && <h3>My Battle Stat Score: {attackerBattleStatScore.toFixed(1)}</h3>}


      <button onClick={() => setShouldFetchStatuses(!shouldFetchStatuses)}>
        {shouldFetchStatuses ? "Stop Fetching" : "Fetch Status for All"}
      </button>

      <button onClick={() => setShowMain(!showMain)}>
        {showMain ? 'Hide Main' : 'Show Main'}
      </button>

      <button onClick={toggleShowFavorites}>
        {showFavorites ? 'Hide Favorites' : 'Show Favorites'}
      </button>
      <button
        onClick={() => setDebuff(prevDebuff => !prevDebuff)}
        style={{
          backgroundColor: 'gold',
          color: 'black',
          border: 'none',
          padding: '10px 15px',
          cursor: 'pointer',
          borderRadius: '4px'
        }}
      >
        Xanax debuff
      </button>




      <button onClick={() => setShowRange(!showRange)}>
        {showRange ? "Hide Estimated Range" : "Show Estimated Range"}
      </button>
      <button onClick={() => setFilterByFaction(!filterByFaction)}>
        {filterByFaction ? "show all targets" : "Filter By Faction / No Factions"}
      </button>
      {filterByFaction && <input
        type="text"
        placeholder="Enter Faction ID or 0"
        value={inputFactionID || ""}
        onChange={(e) => setInputFactionID(e.target.value)}
      />}
      <button onClick={() => setEnterAttackerBattleStatScoreManualy(!enterAttackerBattleStatScoreManualy)}>
        {enterAttackerBattleStatScoreManualy ? "turn off manually" : "Enter BSS manually"}
      </button>
      {enterAttackerBattleStatScoreManualy && <input
        type="text"
        placeholder="Enter BSS"
        value={manualAttackerBattleStatScore || ""}
        onChange={(e) => setManualAttackerBattleStatScore(e.target.value)}
      />
      }

      {error && <p>{error}</p>}
      <div>
        {isMobileView ? (
          <>
            {showFavorites && (
              <div className="card-version">
                <h3>Favorites</h3>
                {timeSinceLastUpdateOfFavorites > oneDayInMilliseconds && (
                  <button onClick={updateFavoritesFromDatabase}>Update Favorites Data</button>
                )}
                {favorites.length > 0 ? (
                  <div>
                    <div className="card-container">
                      {favorites.map((target) => {
                        const timeRemainingString = calculateTimeRemaining(favoriteStatuses[target.defender_ingame_id]?.hospitalTimestamp) || 'N/A';
                        const timeRemainingArray = timeRemainingString.split(":");
                        const minutesRemaining = parseInt(timeRemainingArray[0], 10);
                        const isTimeLessThanThreeMinutes = !isNaN(minutesRemaining) && minutesRemaining <= 3;
                        const isStatusOkay = favoriteStatuses[target.defender_ingame_id]?.state === 'Okay';
                        const isLastActionOnline = favoriteStatuses[target.defender_ingame_id]?.lastAction === 'Online';

                        return (
                          <div className="card" key={target.id}>
                            <h3><a href={`https://www.torn.com/loader.php?sid=attack&user2ID=${target.defender_ingame_id}`} target="_blank" rel="noopener noreferrer">
                              {target.defender_name}
                            </a></h3>
                            <p onClick={() => handleCopyToClipboard(target.defender_ingame_id)} style={{ cursor: 'pointer' }}>
                              Click to copy ID: {target.defender_ingame_id}
                            </p>
                            <p>Faction: {target.faction_name}</p>
                            <p>Battle Stat Score: {target.battlestatscore}</p>
                            {showRange && (
                              <p>
                                Estimated Range: {(() => {
                                  const range = calculateStatsRange(target.battlestatscore);
                                  return range
                                    ? `${Math.round(range.lowest).toLocaleString()} - ${Math.round(range.highest).toLocaleString()}`
                                    : 'N/A';
                                })()}
                              </p>
                            )}

                            <p>FF Multiplier: {(1 + ((8 / 3) * target.battlestatscore) /
                              (enterAttackerBattleStatScoreManualy
                                ? Number(String(manualAttackerBattleStatScore).replace(/,/g, ''))
                                : attackerBattleStatScore)
                            ).toFixed(2)}
                            </p>

                            {shouldFetchStatuses && (
                              <>
                                <p style={{ color: isStatusOkay ? 'green' : 'black' }}>
                                  Status: {favoriteStatuses[target.defender_ingame_id]?.state || 'Loading...'}
                                </p>
                                <p style={{ color: isLastActionOnline ? 'green' : 'black' }}>
                                  Last Action: {favoriteStatuses[target.defender_ingame_id]?.lastAction || 'Loading...'}
                                </p>
                                <p style={{ color: isTimeLessThanThreeMinutes ? 'green' : 'black' }}>
                                  Time Left in Hospital: {timeRemainingString}
                                </p>
                              </>
                            )}
                            {isFavorite(target.defender_ingame_id) ? (
                              <button
                                title="Remove from Favorites"
                                onClick={() => removeFromFavorites(target.id)}
                              >
                                ★
                              </button>
                            ) : (
                              <button
                                title="Add to Favorites"
                                onClick={() => addToFavorites(target)}
                              >
                                ☆
                              </button>
                            )}
                          </div>
                        );
                      })}
                    </div>
                    {/* I'm assuming you might want pagination for favorites too 
          <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
          <button onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
          */}
                  </div>
                ) : (
                  <p>No favorite targets available.</p>
                )}
              </div>)}

            {showMain && <div className="card-version">
              {showFavorites && (
                <h3>Targets Library</h3>)}
              {targetsData.length > 0 ? (
                <div>
                  <div className="card-container">
                    {targetsData.map((target) => {
                      const timeRemainingString = calculateTimeRemaining(userStatuses[target.defender_ingame_id]?.hospitalTimestamp) || 'N/A';
                      const timeRemainingArray = timeRemainingString.split(":");
                      const minutesRemaining = parseInt(timeRemainingArray[0], 10);
                      const isTimeLessThanThreeMinutes = !isNaN(minutesRemaining) && minutesRemaining <= 3;
                      const isStatusOkay = userStatuses[target.defender_ingame_id]?.state === 'Okay';
                      const isLastActionOnline = userStatuses[target.defender_ingame_id]?.lastAction === 'Online';

                      return (
                        <div className="card" key={target.id}>

                          <h3><a href={`https://www.torn.com/loader.php?sid=attack&user2ID=${target.defender_ingame_id}`} target="_blank" rel="noopener noreferrer">
                            {target.defender_name}
                          </a></h3>
                          <p onClick={() => handleCopyToClipboard(target.defender_ingame_id)} style={{ cursor: 'pointer' }}>
                            Click to copy ID: {target.defender_ingame_id}
                          </p>
                          <p>Faction: {target.faction_name}</p>
                          <p>Battle Stat Score: {target.battlestatscore}</p>
                          {showRange && (
                            <p>
                              Estimated Range: {(() => {
                                const range = calculateStatsRange(target.battlestatscore);
                                return range
                                  ? `${Math.round(range.lowest).toLocaleString()} - ${Math.round(range.highest).toLocaleString()}`
                                  : 'N/A';
                              })()}
                            </p>
                          )}

                          <p>FF Multiplier: {(1 + ((8 / 3) * target.battlestatscore) /
                            (enterAttackerBattleStatScoreManualy
                              ? Number(String(manualAttackerBattleStatScore).replace(/,/g, ''))
                              : attackerBattleStatScore)
                          ).toFixed(2)}
                          </p>

                          {shouldFetchStatuses && (
                            <>
                              <p style={{ color: isStatusOkay ? 'green' : 'black' }}>
                                Status: {userStatuses[target.defender_ingame_id]?.state || 'Loading...'}
                              </p>
                              <p style={{ color: isLastActionOnline ? 'green' : 'black' }}>
                                Last Action: {userStatuses[target.defender_ingame_id]?.lastAction || 'Loading...'}
                              </p>
                              <p style={{ color: isTimeLessThanThreeMinutes ? 'green' : 'black' }}>
                                Time Left in Hospital: {timeRemainingString}
                              </p>

                            </>
                          )}
                          {isFavorite(target.defender_ingame_id) ? (
                            <button
                              title="Remove from Favorites"
                              onClick={() => removeFromFavorites(target.id)}
                            >
                              ★
                            </button>
                          ) : (
                            <button
                              title="Add to Favorites"
                              onClick={() => addToFavorites(target)}
                            >
                              ☆
                            </button>
                          )}
                        </div>
                      );
                    })}
                  </div>
                  <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
                  <button onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                </div>
              ) : (
                <p>No targets data available, make sure the API type is limited access</p>
              )}

            </div>}

          </>
        ) : (


          <>

            {showFavorites && (
              <div className="table-version">
                <h3>Favorites</h3>
                {timeSinceLastUpdateOfFavorites > oneDayInMilliseconds && (
                  <button onClick={updateFavoritesFromDatabase}>Update Favorites Data</button>
                )}
                {favorites.length > 0 ? (
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>★</th>
                          <th>Defender Name</th>
                          <th>Defender Id</th>
                          <th>Faction Name</th>
                          <th>Battle Stat Score</th>
                          {showRange && <th>Estimated Range</th>}
                          <th>FF multiplier</th>
                          {shouldFetchStatuses && (
                            <>
                              <th>Status</th>
                              <th>online?</th>
                              <th>Time Left in Hospital</th>
                            </>
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        {favorites.map((target) => {
                          const timeRemainingString = calculateTimeRemaining(favoriteStatuses[target.defender_ingame_id]?.hospitalTimestamp) || 'N/A';
                          const timeRemainingArray = timeRemainingString.split(":");
                          const minutesRemaining = parseInt(timeRemainingArray[0], 10);
                          const isTimeLessThanThreeMinutes = !isNaN(minutesRemaining) && minutesRemaining <= 3;
                          const isStatusOkay = favoriteStatuses[target.defender_ingame_id]?.state === 'Okay';
                          const isLastActionOnline = favoriteStatuses[target.defender_ingame_id]?.lastAction === 'Online';

                          return (
                            <tr className="table-row" key={target.id}>
                              <td>
                                {isFavorite(target.defender_ingame_id) ? (
                                  <button
                                    title="Remove from Favorites"
                                    onClick={() => removeFromFavorites(target.id)}
                                  >
                                    ★
                                  </button>
                                ) : (
                                  <button
                                    title="Add to Favorites"
                                    onClick={() => addToFavorites(target)}
                                  >
                                    ☆
                                  </button>
                                )}
                              </td>
                              <td>
                                <a href={`https://www.torn.com/loader.php?sid=attack&user2ID=${target.defender_ingame_id}`} target="_blank" rel="noopener noreferrer">
                                  {target.defender_name}
                                </a>
                              </td>
                              <td onClick={() => handleCopyToClipboard(target.defender_ingame_id)} style={{ cursor: 'pointer' }}>
                                {target.defender_ingame_id}
                              </td>
                              <td>{target.faction_name}</td>
                              <td>{target.battlestatscore}</td>
                              {showRange && (
                                <td>
                                  {(() => {
                                    const range = calculateStatsRange(target.battlestatscore);
                                    return range
                                      ? `${Math.round(range.lowest).toLocaleString()} - ${Math.round(range.highest).toLocaleString()}`
                                      : 'N/A';
                                  })()}
                                </td>
                              )}
                              <td>
                                {(1 + ((8 / 3) * target.battlestatscore) /
                                  (enterAttackerBattleStatScoreManualy
                                    ? Number(String(manualAttackerBattleStatScore).replace(/,/g, ''))
                                    : attackerBattleStatScore)
                                ).toFixed(2)}
                              </td>
                              {shouldFetchStatuses && (
                                <>
                                  <td style={{ backgroundColor: isStatusOkay ? 'green' : 'transparent' }}>
                                    {favoriteStatuses[target.defender_ingame_id]?.state || 'Loading...'}
                                  </td>
                                  <td style={{ backgroundColor: isLastActionOnline ? 'green' : 'transparent' }}>
                                    {favoriteStatuses[target.defender_ingame_id]?.lastAction || 'Loading...'}
                                  </td>
                                  <td style={{ backgroundColor: isTimeLessThanThreeMinutes ? 'green' : 'transparent' }}>
                                    {timeRemainingString}
                                  </td>
                                </>
                              )}
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                    {/* Pagination if you want it for favorites */}
                  </div>
                ) : (
                  <p>No favorite targets available.</p>
                )}
              </div>
            )}


            {showMain && <div className="table-version">
              {targetsData.length > 0 ? (
                <div>
                  <table>
                    <thead>
                      <tr>
                        <th>☆</th>
                        <th>Defender Name</th>
                        <th>Defender Id</th>
                        <th>Faction Name</th>
                        <th>Battle Stat Score</th>
                        {showRange && <th>Estimated Range</th>}
                        <th>FF multiplier</th>
                        {shouldFetchStatuses && (
                          <>
                            <th>Status</th>
                            <th>online?</th>
                            <th>Time Left in Hospital</th> {/* You missed this in your original code */}
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {targetsData.map((target) => {
                        const timeRemainingString = calculateTimeRemaining(userStatuses[target.defender_ingame_id]?.hospitalTimestamp) || 'N/A';
                        const timeRemainingArray = timeRemainingString.split(":");
                        const minutesRemaining = parseInt(timeRemainingArray[0], 10);
                        const isTimeLessThanThreeMinutes = !isNaN(minutesRemaining) && minutesRemaining <= 3;
                        const isStatusOkay = userStatuses[target.defender_ingame_id]?.state === 'Okay';
                        const isLastActionOnline = userStatuses[target.defender_ingame_id]?.lastAction === 'Online';

                        return (
                          <tr className="table-row" key={target.id}>
                            <td>
                              {isFavorite(target.defender_ingame_id) ? (
                                <button
                                  title="Remove from Favorites"
                                  onClick={() => removeFromFavorites(target.id)}
                                >
                                  ★
                                </button>
                              ) : (
                                <button
                                  title="Add to Favorites"
                                  onClick={() => addToFavorites(target)}
                                >
                                  ☆
                                </button>
                              )}
                            </td>
                            <td >
                              <a href={`https://www.torn.com/loader.php?sid=attack&user2ID=${target.defender_ingame_id}`} target="_blank" rel="noopener noreferrer">
                                {target.defender_name}
                              </a>
                            </td >
                            <td onClick={() => handleCopyToClipboard(target.defender_ingame_id)} style={{ cursor: 'pointer' }}>
                              {target.defender_ingame_id}
                            </td>
                            <td  >{target.faction_name}</td>
                            <td  >{target.battlestatscore}</td>
                            {showRange && (
                              <td  >
                                {(() => {
                                  const range = calculateStatsRange(target.battlestatscore);
                                  return range
                                    ? `${Math.round(range.lowest).toLocaleString()} - ${Math.round(range.highest).toLocaleString()}`
                                    : 'N/A';
                                })()}
                              </td>
                            )}

                            <td  >
                              {(1 + ((8 / 3) * target.battlestatscore) /
                                (enterAttackerBattleStatScoreManualy
                                  ? Number(String(manualAttackerBattleStatScore).replace(/,/g, ''))
                                  : attackerBattleStatScore)
                              ).toFixed(2)}
                            </td>


                            {shouldFetchStatuses && (
                              <>
                                <td style={{ backgroundColor: isStatusOkay ? 'green' : 'transparent' }}>
                                  {userStatuses[target.defender_ingame_id]?.state || 'Loading...'}
                                </td>
                                <td style={{ backgroundColor: isLastActionOnline ? 'green' : 'transparent' }}>
                                  {userStatuses[target.defender_ingame_id]?.lastAction || 'Loading...'}
                                </td>
                                <td style={{ backgroundColor: isTimeLessThanThreeMinutes ? 'green' : 'transparent' }}>
                                  {timeRemainingString}
                                </td>
                              </>
                            )}
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                  <button disabled={currentPage === 1} onClick={() => setCurrentPage(currentPage - 1)}>Previous</button>
                  <button onClick={() => setCurrentPage(currentPage + 1)}>Next</button>
                </div>
              ) : (
                <p>No targets data available, make sure the API type is limited access</p>
              )}
            </div>}
          </>
        )}
        {/* Your navigation buttons JSX can go here if they are shared between the two views */}
      </div>


      <form onSubmit={handleSubmit}>
        {!apiKey && (
          <div>
            <h1>WAIT!</h1>
            <p>Once a valid limited access API is entered, it will be used to calculate information about targets you attacked recently, including their battlestatscore, by providing this API you understand that this target information will be available publicly both to you, but also every other player wishing to use this torn tool</p>
          </div>
        )}
        <label>
          API Key:
          <input type="text" value={apiKey} onChange={(e) => setApiKey(e.target.value)}></input>
        </label>
        <input type="submit" value="Save API Key" />
      </form>

      {accessLevel && accessLevel < 3 && (

        <form onSubmit={handleBattleStatSubmit}>
          <label>
            Enter your <a href="https://www.torntargets.com/BSS" target="_blank" rel="noreferrer">BattleStatScore</a>:
            <input
              type="text"
              value={battleStatInput}
              onChange={(e) => {
                const cleanedInput = e.target.value.replace(/,/g, '');
                setBattleStatInput(cleanedInput)
              }}
            />
          </label>
          <p>You are currently using a public/minimal access key, targets you recently attacked will not be added/updated, this is not reccomended if you want to contribute data about enemies during an active war, but its perfectly fine for chaining</p>
          <button type="submit">Submit BattleStatScore</button>
        </form>
      )}
    </div>
  );
}
export default TargetsLibrary;
// ApiKeyForm.js
import React, { useState, useEffect } from 'react';
import { supabase } from '../../supabaseClient';
import { useUserId } from '../../hooks/authversionhooks/useUserId';

const ApiKeyForm = () => {
  const [apiKey, setApiKey] = useState('');
  const userId = useUserId();
  
  useEffect(() => {
    fetchApiKey();
  }, [userId]);

  const fetchApiKey = async () => {
    if (userId) {
      const { data } = await supabase
        .from('UserApiKey')
        .select('api_key')
        .eq('user_id', userId)
        .single();
          
      if (data) {
        setApiKey(data.api_key);
      }
    }
  };

  const handleSubmit = async event => {
    event.preventDefault();
  
    if (!userId) {
      console.log("User is not authenticated.");
      return;
    }
  
    // Check if a row with the given user_id already exists
    const { data: existingData } = await supabase
      .from('UserApiKey')
      .select('user_id')
      .eq('user_id', userId)
      .single();
  
    let data, error;
  
    if (existingData) {
      // If it exists, update it
      ({ data, error } = await supabase
        .from('UserApiKey')
        .update({ api_key: apiKey })
        .eq('user_id', userId));
    } else {
      // If it doesn't exist, insert a new row
      ({ data, error } = await supabase
        .from('UserApiKey')
        .insert([{ user_id: userId, api_key: apiKey }]));
    }
  
    if (error) {
      console.log("Error updating/inserting API Key: ", error.message);
    } else if (data) {
      setApiKey(apiKey); // You already have the apiKey, just set it directly
    }
  };
  

  return (
    <form onSubmit={handleSubmit}>
      <h3>entering an API key here will store it in the server</h3>
      <p>if you wouldn't mind to contribute target information to the public library of targets without storing your API on the server please go <a href="/">here instead</a></p>
      <label>
        API Key:
        <input type="text" value={apiKey} onChange={(e) => setApiKey(e.target.value)} />
      </label>
      <input type="submit" value="Save API Key" />
    </form>
  );
};

export default ApiKeyForm;

import { useState } from 'react';
import { supabase } from '../../supabaseClient';

const useKickMember = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const kickMember = async (groupId, memberNameToKick) => {
        setLoading(true);
        try {
            // Get userId from group_member table using memberNameToKick
            const { data: userData, error: userError } = await supabase
                .from('group_member')
                .select('user_id')
                .eq('group_id', groupId)
                .eq('user_player_name', memberNameToKick)
                .single();

            if (userError || !userData) {
                throw userError || new Error("User not found");
            }

            // Then proceed with kicking out the member
            const { error: kickError } = await supabase
                .from('group_member')
                .delete()
                .eq('group_id', groupId)
                .eq('user_id', userData.user_id);

            if (kickError) {
                throw kickError;
            }
        } catch (error) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    };

    return {
        kickMember,
        loading,
        error,
    };
};

export default useKickMember;

import React, { useState, useEffect } from "react";
import { supabase } from '../../supabaseClient';
import ApiKeyForm from "./ApiKeyForm";
import useCreateGroup from '../../hooks/authversionhooks/useCreateGroup';
import { useUserId } from '../../hooks/authversionhooks/useUserId';
import useGroupLeadership from '../../hooks/authversionhooks/useGroupLeadership';
import GroupRequests from './GroupRequests';
import useRequestToJoinGroup from '../../hooks/authversionhooks/useRequestToJoinGroup';
import useTornUserName from '../../hooks/authversionhooks/useTornUserName';
import useGroupRequest from '../../hooks/authversionhooks/useGroupRequest';
import useUserGroup from '../../hooks/authversionhooks/useUserGroup';
import useLeaveGroup from '../../hooks/authversionhooks/useLeaveGroup';
import useKickMember from '../../hooks/authversionhooks/useKickMember';
import './SignInPage.css';


export default function SignInPage() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  const groupCurrentUserId = useUserId();
  const { groupName: leaderGroupName, groupMembers } = useGroupLeadership();
  const { createGroup, loading: createGroupLoading, error: createGroupError } = useCreateGroup();
  const [groupCreated, setGroupCreated] = useState(false);

  const requestCurrentUserId = useUserId();
  const currentPlayerName = useTornUserName();
  const { requestToJoinGroup, loading: requestJoinLoading, error: requestJoinError } = useRequestToJoinGroup();
  const [requestSent, setRequestSent] = useState(false);
  const { groupRequest, deleteGroupRequest, loading: groupRequestLoading, error: groupRequestError } = useGroupRequest(requestCurrentUserId);
  const { userGroup, loading: userGroupLoading, error: userGroupError } = useUserGroup(requestCurrentUserId);
  const { leaveGroup, loading: leaveGroupLoading, error: leaveGroupError } = useLeaveGroup();
  const { kickMember, loading: kickMemberLoading, error: kickMemberError } = useKickMember();
  const [memberNameToKick, setMemberNameToKick] = useState('');
  const [groupName, setGroupName] = useState('');
  const [createGroupName, setCreateGroupName] = useState('');  // for creating a group
const [joinGroupName, setJoinGroupName] = useState('');     // for joining a group

  useEffect(() => {
    const sessionUser = supabase.auth.getUser();
    setUser(sessionUser);

    const { data: authListener } = supabase.auth.onAuthStateChange(
      (_event, sessionUser) => {
        setUser(sessionUser);
      }
    );

    return () => {
      authListener?.subscription.unsubscribe();
    };
  }, []);

  const handleSignUp = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { user, error } = await supabase.auth.signUp({ email, password });
      if (error) throw error;
      alert("Check your email for the login link!");
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignIn = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { user, error } = await supabase.auth.signInWithPassword({ email, password });
      if (error) throw error;
      else alert("You're signed in");
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleSignOut = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      else alert("You're signed out");
    } catch (error) {
      alert(error.error_description || error.message);
    } finally {
      setLoading(false);
    }
  }

  if (user) {


    const handleCreateGroup = async () => {
      const newGroup = await createGroup(groupCurrentUserId, createGroupName);
      if (newGroup) {
          setGroupCreated(true);
      }
  };
  

    

  const handleRequestToJoinGroup = async () => {
    const request = await requestToJoinGroup(joinGroupName, requestCurrentUserId, currentPlayerName);
    if (request) {
        setRequestSent(true);
    }
};


    const handleLeaveGroup = async () => {
        if (userGroup) {
            await leaveGroup(userGroup, requestCurrentUserId);
        }
    };

    const handleKickMember = async () => {
        if (userGroup && memberNameToKick) {
            await kickMember(userGroup, memberNameToKick);
        }
    };

    return (
        <div>
            <button onClick={handleSignOut} disabled={requestJoinLoading || createGroupLoading || loading}>
                Sign Out
            </button>
            <ApiKeyForm user={user} />

            


            {/* RequestToJoinGroup content */}
<div>
    
    
    {/* If user is NOT a member of any group and has NOT issued a request for the current group */}
    {!userGroup && !groupRequest && (
        <div>
          <h3>Request to Join a Group</h3>
    <p>please enter the group name told to you by your faction</p>
    <p>if you want to join a general group that is unrelated to you faction, join "test"</p>
    <input
    type="text"
    value={joinGroupName}
    onChange={e => setJoinGroupName(e.target.value)}
    placeholder="Enter group name"
/>

            <button onClick={handleRequestToJoinGroup} disabled={requestJoinLoading}>
                {requestJoinLoading ? 'Sending Request...' : 'Send Request'}
            </button>
            {requestSent && <p>Request to join {groupName} sent!</p>}
            {requestJoinError && <p>Error: {requestJoinError}</p>}
        </div>
    )}

    {/* If the user has already issued a request to join */}
    {groupRequest && (
        <div>
            <button onClick={deleteGroupRequest} disabled={groupRequestLoading}>
                {groupRequestLoading ? 'Deleting Request...' : 'Delete Request'}
            </button>
            {groupRequestError && <p>Error: {groupRequestError}</p>}
        </div>
    )}

    {/* If the user is already a member of a group */}
    {userGroup && !userGroupLoading && (
        <div>
            <button onClick={handleLeaveGroup} disabled={leaveGroupLoading}>
                {leaveGroupLoading ? 'Leaving group...' : 'Leave Current Group'}
            </button>
            <input
                type="text"
                value={memberNameToKick}
                onChange={e => setMemberNameToKick(e.target.value)}
                placeholder="Enter member name to kick"
            />
            <button onClick={handleKickMember} disabled={kickMemberLoading}>
                {kickMemberLoading ? 'Kicking member...' : 'Kick Member'}
            </button>
            {leaveGroupError && <p>Error: {leaveGroupError}</p>}
            {kickMemberError && <p>Error: {kickMemberError}</p>}
        </div>
    )}
</div>

        {/* Group Page content */}
<div>
    <h3>Create Group</h3>
    <p>it is Not reccomended to create a group if you are new to the website</p>
    <input
    type="text"
    placeholder="Group Name"
    value={createGroupName}
    onChange={e => setCreateGroupName(e.target.value)}
/>

    {!groupCreated ? (
        <button onClick={handleCreateGroup} disabled={createGroupLoading || !createGroupName}>
            {createGroupLoading ? 'Creating...' : 'Create Group'}
        </button>
    ) : (
        <p>Group successfully created!</p>
    )}
    {createGroupError && <p>Error: {createGroupError}</p>}
    {leaderGroupName && (
        <div>
            {/* This section will show up only if leaderGroupName is truthy i.e., if the current user is a leader */}
            <GroupRequests groupName={leaderGroupName} />
            {groupMembers.length > 0 && (
                <div>
                    <h2>Your Group Members</h2>
                    <ul>
                        {groupMembers.map((member, index) => <li key={index}>{member}</li>)}
                    </ul>
                </div>
            )}
        </div>
    )}
</div>

        </div>
    );
}


  return (
    <div>
      
      <form>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button onClick={handleSignUp} disabled={loading}>
          Sign Up
        </button>
        <button onClick={handleSignIn} disabled={loading}>
          Sign In
        </button>
        <p>This part of the Site is an Abandoned project for now and the foreseeable future, Sign in still enabled, Sign Up is disabled.</p>
      </form>
    </div>
  );
}
